<template>
  <v-dialog v-model="show" max-width="400">
    <v-card>
      <v-card-title class="body-1">
        <v-spacer></v-spacer>
        <v-btn icon small @click="show = false">
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <login-form :text-color="'primary--text'" :primary-shadow="true" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'LoginDialog',
  components: {
    LoginForm: () =>
      import(/* webpackChunkName: "LoginForm" */ '@/components/Login/LoginForm')
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  computed: {
    ...mapGetters('authentication', ['isAuthenticated']),
    show: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit('close')
        }
      }
    }
  },
  watch: {
    isAuthenticated(newValue) {
      if (newValue === true) {
        this.show = false
      }
    }
  }
}
</script>
